import * as React from "react"
import { sbEditable } from "@storyblok/storyblok-editable"
import DynamicComponent from "../components/dynamicComponent"
import "./editorial.css"

const Editorial = ({ blok }) => (
  <div className="editorial-wrapper" style={{ background:blok.background_color}}>
    <div className="editorial" style={{ flexDirection:blok.direction,flexWrap:"wrap", justifyContent:blok.justify_content}}  {...sbEditable(blok)}>
        {  blok.items.map(item => {
            return (<DynamicComponent blok={item} key={item._uid} />)
          })
        } 
    </div>
  </div>
)


export default Editorial
