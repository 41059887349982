import * as React from "react"
import { sbEditable } from "@storyblok/storyblok-editable"
const InputCheckboxGroup = ({ blok, key }) => {
        return(
            <div {...sbEditable(blok)} key={key} >
                <label dangerouslySetInnerHTML={{ __html: blok.label }} />
                { blok.items.map((item) => { return (<InputCheckboxItem item={item} name={blok.name} key={item._uid} />) })}
            </div>    
        )
    

}

export default InputCheckboxGroup

const InputCheckboxItem = ({item, name }) => { 
    return (
        
        <label className="input"><input type="checkbox" name={`${name}[]`} value={item.value} /><span dangerouslySetInnerHTML={{ __html: item.text }} /></label>
    )
}