module.exports = {
  plugins: [
    `gatsby-plugin-react-helmet`,
    {
      resolve: 'gatsby-source-storyblok',
      options: {
        accessToken: process.env.STORYBLOK_TOKEN || 'maBEzapBdLBfv6nr608mQwtt',
        version: process.env.NODE_ENV === 'production' ? 'published' : 'draft'
        // languages: ['de', 'at'] // Optional parameter. Omission will retrieve all languages by default.
      }
    },
    {
      resolve: "gatsby-plugin-google-tagmanager",
      options: {
        id: process.env.GTM_ID || "GTM-5P6GDPD",
        includeInDevelopment: true,
        //defaultDataLayer: { platform: "gatsby" },
        enableWebVitalsTracking: true
      }
    }
  ],
}
