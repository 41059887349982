import { useStaticQuery, graphql } from "gatsby"
export const useSettings = (lang) => {
    const { settings } = useStaticQuery(graphql`
    query Settings {
      settings: allStoryblokEntry(filter: {field_component: {eq: "settings"}}) {
        edges {
          node {
            content
            lang
          }
        }
      }
    } 
    `)


    const result = settings.edges.filter(obj => { console.log(obj); return obj.node.lang === lang }) 
    console.log('result:', result, lang)
    return JSON.parse(result[0].node.content)
}