import * as React from "react"
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import { sbEditable } from "@storyblok/storyblok-editable"
import { Gallery, Item } from 'react-photoswipe-gallery'
import "./gallery.css"

const ImageGallery = ({ blok }) => (
  <div className="image-gallery" style={{maxWidth:'1200px', margin:"auto", display:"flex", width:"100%", flexFlow:"wrap", justifyContent: "space-around"}}>
    <Gallery {...sbEditable(blok)} shareButton={false} fullscreenButton={false} zoomButton={false}>
        { blok.photos.map((item, key) => {
            return(
                <Item
                    original={`${item.filename}/m/`}
                    thumbnail={`${item.filename}/m/1024x0`}
                    key={key}
                    width="1024"
                    height="768"
                    html="test"
                >
                    {({ ref, open }) => (
                        <div className="ig_img">
                        <img ref={ref} onClick={open} data-ga-category="gallery" data-ga-action="open" data-ga-label={item.alt}  src={`${item.filename}/m/768x0`} alt={item.alt || "Gallery image"} />
                        <i>{item.name}</i>
                        </div>
                    )}
                </Item>
            )
        }) }
    </Gallery>
  </div>
)


export default ImageGallery
