import * as React from "react"
import { sbEditable } from "@storyblok/storyblok-editable"
import DynamicComponent from "./dynamicComponent"
import "./form.css"

const Form = ({ blok }) => {
    return (
        <div className={`form ${blok.width || 'w-100'}`} style={{ backgroundColor:(blok.background_color ? blok.background_color : 'none'), textAlign:blok.horizontal_align || null }} {...sbEditable(blok)}>
            <form method="POST" action={blok.post_url}>
                {  
                    blok.inputs && blok.inputs.map((item) => {
                        return (<DynamicComponent blok={item}  key={item._uid} />)
                    })
                }
                <div style={{ float:"left", width:"100%"}} {...sbEditable(blok.button_text)}>
                <button className="btn" type="submit">{blok.button_text}</button>
                </div>
            </form>
        </div>
    )    
}


export default Form
