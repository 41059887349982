import * as React from "react"
import { sbEditable } from "@storyblok/storyblok-editable"
const InputText = ({ blok, key }) => {

    const name = blok.name || `input-${key}`

    if (blok.type === 'textarea'){
        return(
            <label htmlFor={name} {...sbEditable(blok)}><span dangerouslySetInnerHTML={{ __html: blok.label }} />
                <textarea id={name} name={name} required={blok.required} />   
            </label>                    
        )
    }
    else if (blok.type === 'hidden'){
        return(
            <input type="hidden" name={name} value={blok.label} />
        )
    }
    else{
        return(
            <label htmlFor={name}  {...sbEditable(blok)}>{ blok.label  }
                <input type={blok.type || 'text'} name={name} id={name} placeholder={ blok.placeholder || blok.label } required={blok.required} />
            </label> 
        )
    }


}

export default InputText
