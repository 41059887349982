import * as React from "react"
import { useState } from "react"
import { Link } from "gatsby"
import { AHref } from "../lib/link"
import { sbEditable } from "@storyblok/storyblok-editable"

import "./header.css"

const Header = ({ settings }) => {

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => { setIsOpen(!isOpen); };

  return (
    <header className={`${settings.menu_type || null}`}>
      <div className="mh_wrapper">
        <div className={`brand ${settings.logo_alignment ? 'logo-'+settings.logo_alignment : null}`}>
          <Link
            to="/"
            style={{
              textDecoration: `none`,
            }}
            data-ga-category="menu" 
            data-ga-action="logo click" 
          >
            {
              settings.logo.map((blok, key) => {
                const desktopFilename = !blok.image.filename.endsWith('.svg') ? blok.image.filename + '/m/400x0' : blok.image.filename; 
                const mobileFilename =  blok.mobile_image.filename ? (blok.mobile_image.filename.endsWith('.svg') ? blok.mobile_image.filename : blok.mobile_image.filename + '/m/400x0') : desktopFilename;
              
                return (
                  <picture key={key} {...sbEditable(blok)}>
                    <source srcSet={`${mobileFilename}`} media="(max-width:768px)" />
                    <source srcSet={`${desktopFilename}`} media="(min-width:769px)" />
                    <img src={`${mobileFilename}`}  alt={`${blok.image.alt || 'Image' }`} />          
                  </picture>
                )
              })
            }
          </Link>
        </div>
        <div className="mh-mobile-menu">
          <button id="navbutton" alt="Close menu" onClick={toggle} aria-expanded={isOpen} aria-controls="mh-mobile-navigation" className={`${isOpen ? 'open' : 'closed'}`} >
            { /* <span className="mh-sr-only">Toggle navigation</span> */ }
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </div >
      <nav id="mh-mobile-navigation" role="navigation" aria-label="Navigation" className={`${isOpen ? 'open' : 'closed'} ${settings.logo_alignment ? 'logo-'+settings.logo_alignment : null}`} >
          <ul>
              { settings.menu_main.map((item, key) => {
                  return (<li key={key}><AHref className={ item.item_type} activeClassName="menu-active" link={item.link} data-ga-category="menu" data-ga-action="header click" data-ga-label={item.link_text}>{ item.link_text}</AHref></li>)
              })}
          </ul>
        </nav>
    </header >
  )
}




export default Header
