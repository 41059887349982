import * as React from "react"

import Layout from "../components/layout"
import Head from "../components/head"
import DynamicComponent from "../components/dynamicComponent"
import useStoryblok from "../lib/storyblok"

const Page = ({ pageContext, location }) => { 
  let story = pageContext.story
  story = useStoryblok(story, location)

  if (story.content && story.content.body){
    const components = story.content.body.map(blok => {
      console.log('dynamic:', story.lang)
      return (<DynamicComponent blok={blok} key={blok._uid} lang={ story.lang }/>)
    })
  
    return (
    <Layout location={location} lang={story.lang} >
      <Head {...story} />
      { components }
    </Layout>
  )    
  }
}

export default Page
