import * as React from "react"
import { render, MARK_LINK } from "storyblok-rich-text-react-renderer"
import { sbEditable } from "@storyblok/storyblok-editable"
import { Link } from "gatsby"
import "./text.css"

const Text = ({ blok }) => (
  <div className={`txt ${blok.width || 'w-100'}`} style={{ color: blok.color || "#000000", backgroundColor:(blok.background_color ? blok.background_color : 'none'), textAlign:blok.horizontal_align || null }} {...sbEditable(blok)}>
      {
        render(
          blok.text, {
          markResolvers: {
              [MARK_LINK]: (children, props) => {
                  const { href, target, linktype } = props;
                  if (linktype === 'email') {
                      // Email links: add `mailto:` scheme and map to <a>
                      return <a href={`mailto:${href}`}>{children}</a>;
                  }
                  if (href.match(/^(https?:)?\/\//)) {
                      // External links: map to <a>
                      return <a href={'/' + href} target={target}>{children}</a>;
                  }
                  // Internal links: map to <Link>
                  return <Link to={href} >{children}</Link>;
              }
          }
        })
      }    
  </div>
)


export default Text
