import * as React from "react"
import { sbEditable } from "@storyblok/storyblok-editable"
import DynamicComponent from "../components/dynamicComponent"
import "./footer.css"

const Footer = ({ settings }) => (
  <footer {...sbEditable(settings)}>
    <div className="footer_menu_wrapper">
      <div className="footer_menu">
          {  settings.footer_content.map(blok => {
              return (<DynamicComponent blok={blok} key={blok._uid} />)
            })
          } 
      </div>
    </div>
    <div className="copyright_wrapper">
      <div className="copyright">
        <p>© {new Date().getFullYear()} { settings.copyright}</p>
      </div>
    </div>
  </footer>
)


export default Footer
