import * as React from "react"
import { Link } from "gatsby"

export function AHref(props){

    if (props.link && props.link.linktype === 'story'){
        const { link, ...newProps} = props
        const url = props.link.cached_url     
        return(

            <Link to={'/' + url} {...newProps}>
                { props.children }
            </Link>
        )
    }
    else if (props.link && props.link.linktype){
        const {link, activeClassName, ...extLink } = props
        const url = props.link.url
        return(
            <a href={url} {...extLink} >{props.children}</a>
        )
    }
    else{
        console.log(props)
        return null

    }
    



}

