/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { Helmet } from "react-helmet"

function Head(story) {

  const lang = story.lang === 'default' ? 'en' : story.lang;
  const title = story.content.SEO ? story.content.SEO.title || story.name : story.name;
  let meta = {}

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      /*titleTemplate={defaultTitle ? `%s - ${defaultTitle}` : null}*/
      meta={[
        {
          name: `description`,
          content: story.content.SEO ? story.content.SEO.description : null,
        },
        {
          property: `og:title`,
          content: story.content.SEO ? story.content.SEO.og_title || story.content.SEO.title || story.name : null,
        },
        {
          property: `og:description`,
          content: story.content.SEO ? story.content.SEO.og_description || story.content.SEO.description : null,
        },
        {
          property: `og:image`,
          content: story.content.SEO ? story.content.SEO.og_image : null,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        /*{
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },*/
      ].concat(meta)}
    >
    </Helmet>
  )
}


export default Head
