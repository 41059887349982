import * as React from "react"
import { sbEditable } from "@storyblok/storyblok-editable"
import { useSettings } from "../hooks/settings"
import "./banner.css"
import Text from "./text"

const Banner = ({ blok, lang }) => {

  console.log('banner lang', lang)
  const settings = useSettings(lang)
  
  const desktopFilename = !blok.image_desktop.filename.endsWith('.svg') ? blok.image_desktop.filename + '/m/1600x0' : blok.image_desktop.filename; 
  const mobileFilename =  blok.image_mobile.filename ? (blok.image_mobile.filename.endsWith('.svg') ? blok.image_mobile.filename : blok.image_mobile.filename + '/m/0x768') : blok.image_desktop.filename + '/m/0x768';
  const textBlok = { text : blok.text, horizontal_align: blok.text_align, color: blok.text_color }
  return (
    <div className={`banner ${blok.image_type || 'b__bg_full'}`} {...sbEditable(blok)}>
      <picture>
        <source srcSet={`${mobileFilename}`} media="(max-width:768px)" />
        <source srcSet={`${desktopFilename}`} media="(min-width:769px)" />
        <img className="pseudo-background-img" src={`${mobileFilename}`} alt={blok.image_desktop.alt} />
      </picture>
        <div className={`b__content ${blok.text_vertical_align} ${blok.text_horizontal_align}`}>
          <aside className={`w-50`}>
            <div className={`b__content__message`} > 
              <Text blok={textBlok} />
            </div>
          </aside>
        </div>
        <div className="scroll-indicator">
          <h4>{ settings.scroll }</h4>
          <svg className="arrows">          
							<path className="a1" d="M0 0 L30 32 L60 0"></path>
							<path className="a2" d="M0 20 L30 52 L60 20"></path>
							<path className="a3" d="M0 40 L30 72 L60 40"></path>
						</svg>
        </div>
        
    </div>
  )  
}





export default Banner
