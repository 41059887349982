import Menu from "./menu"
import Text from "./text"
import React from "react"
import Editorial from "./editorial"
import Image from "./image"
import Gallery from "./gallery"
import Banner from "./banner"
import Form from "./form"
import InputText from "./input_text"
import InputCheckboxGroup from "./input_checkbox_group"

const Components = {
  'menu': Menu,
  'text': Text,
  'editorial': Editorial,
  'image' : Image,
  'gallery' : Gallery,
  'banner' : Banner,
  'form' : Form,
  'input_text' : InputText,
  'input_checkbox_group' : InputCheckboxGroup
}

const DynamicComponent = ({ blok, lang }) => {
  if (typeof Components[blok.component] !== 'undefined') {
    const Component = Components[blok.component]
    return (<Component blok={blok} lang={lang} />)
  }
  return (<p>The component <strong>{blok.component}</strong> has not been created yet.</p>)
}

export default DynamicComponent