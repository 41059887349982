import * as React from "react"
import { AHref } from "../lib/link"
import { sbEditable } from "@storyblok/storyblok-editable"
import "./menu.css"

const Menu = ({ blok }) => (

    <nav {...sbEditable(blok)} className="menu">
        {blok.header && (<h4>{blok.header}</h4>)}
        <ul>
            { blok.items.map((item, key) => {
                return (<li key={key}><AHref link={item.link} data-ga-category="menu" data-ga-action="footer click" data-ga-label={item.link_text} >{ item.link_text}</AHref></li>)
            })}
        </ul>
    </nav>

)

export default Menu