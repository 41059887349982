/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useSettings } from "../hooks/settings"
import Header from "./header"
import Footer from "./footer"
import "normalize.css"
import "./layout.css"

const Layout = ({ children, location, lang }) => {

  const settings = useSettings(lang)

  return (
    <>
      <Header settings={ settings } />
      <main>{children}</main>
      <Footer settings={settings} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
