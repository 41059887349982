import * as React from "react"
import { sbEditable } from "@storyblok/storyblok-editable"
import "./image.css"
const Image = ({ blok }) => {
    const desktopFilename = !blok.image.filename.endsWith('.svg') ? blok.image.filename + '/m/1600x0' : blok.image.filename; 
    const mobileFilename =  blok.mobile_image.filename ? (blok.mobile_image.filename.endsWith('.svg') ? blok.mobile_image.filename : blok.mobile_image.filename + '/m/768x0') : blok.image.filename + '/m/768x0';

  return (
    <div className={`img ${blok.width || 'w-100'} pl-${blok.image_placement || 'foreground'}`} style={{ backgroundImage: blok.image_placement === 'background' ? `url(${blok.image.filename}/m/0x768)` : null }} {...sbEditable(blok)}>
        { 
          blok.image_placement !== 'background' && (
            <picture>
                <source srcSet={`${mobileFilename}`} media="(max-width:768px)" />
                <source srcSet={`${desktopFilename}`} media="(min-width:769px)" />
                <img src={`${mobileFilename}`} alt={`${blok.image.alt || 'Image' }`} />          
            </picture>
          )
        }
    </div>
  )}


export default Image
